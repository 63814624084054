/* responsive   */
@media only screen and (width >= 1200px)  {
  
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  
}

@media only screen  and (width >= 768px) and (width <= 991px) {
  .ContactleftPoint i{font-size: 20px; width: 36px !important;    height: 24px;}
  .ContactleftPoint span{font-size: 20px;}
  .ContactleftPoint { padding: 17px 10px 10px 74px;}
  .ContactFormouter{width: 142%;}
  #Icon {    top: 59%;}
  .ContactLeftPart{    width: 40%;}
  .ContactManinOuter h1{font-size: 32px;}

  /* .intro .overlay {height: 84vh;} */
  
}

@media only screen  and (width >= 640px) and (width <= 767px) {
  .ContactRightPart {top: 475px;}

  /* .intro .overlay {height: 75vh;} */
  .intro h1 {font-size: 50px;}
  header .intro-text {    padding-top: 125px; padding-bottom:0 }
  .aboutImg img{margin: 0 auto !important;}
  .about-text h2{ margin: 37px auto !important;}
  .careerGrid {  grid-template-columns: repeat(2, 1fr);}
  #Icon {    top: 71%;}

  
  
}

@media only screen  and (width >= 450px) and (width <= 639px) {
  .intro h1 {font-size: 40px;}

  /* .intro .overlay {height: 48vh;} */
  header .intro-text {    padding-top: 100px; padding-bottom: 0;}
  #about {    padding: 15px 0;}
  #about h2 {font-size: 35px};
  #testimonials {    padding: 30px 0;}
  #features {    padding: 50px 0;}
  .grid-container {    grid-template-columns: repeat(2, 1fr);}
  .letsright .form-group {  width: 87%;}
  .ContactFormouter {margin-top: 300px;}
  #Icon {    top: 59%;}

}

@media only screen  and (width >= 390px) and (width <= 449px) {
  .intro h1 {font-size: 25px;}

  /* .intro .overlay {height: 40vh;} */
  header .intro-text {    padding-top: 93px; padding-bottom: 0;}
  .intro p {    font-size: 12px;}
  #about img {    width: 73%; margin: 30px auto}
  #about, #services, #testimonials, #team, #contact, #footer,#features{width: 100% !important ;}
  .grid-container {    grid-template-columns: repeat(2, 1fr);}
  #Icon {    top: 59%;}
  .ContactRightPart {    width: 137%;    margin-left: -67px;    margin-top: 516px;  }
  .ContactleftPoint i {left: 16px;    top: 24px;    width: 42px !important;    height: 32px; font-size: 23px;}
  .ContactleftPoint span {font-size: 19px;}
  .ContactManinOuter h1 {font-size: 28px};
  .navbar-brand img {    width: 50px;    height: 50px;    top: -4px;    left: 0;}
  .navbar-toggle {    position: relative;    right: 0;    top: -14px;}
  .navbar-brand img {    width: 60px;    height: 60px;    top: -4px;    left: 0;}

  .letsouter{flex-direction: column;}
.letsleft, .letsright{width: 100%;}
/* .letsouter svg {  height: 40rem;} */
.letsright{margin-top: -90px;}
.letsright .form-group {  width: 93%;}
.careerGrid {  grid-template-columns: repeat(1, 1fr);}

.careerOuter .add-file-btn{ margin-bottom: 20px;}
.message-label span:nth-of-type(2) { margin-left: 21px; }
#innerIcon{top: 0.1rem;}
.add-file-btn .fa{margin-right: 10px;}
.popup-content {top: -10px;}

  


}

@media only screen  and (width >= 320px) and (width <= 389px) {
  .intro h1 {font-size: 25px;}

  /* .intro .overlay {height: 37vh;} */
  header .intro-text {    padding-top: 36px; padding-bottom: 0;}
  .intro p {    font-size: 12px;}
  #about img {    width: 73%; margin: 30px auto}
  #about, #services, #testimonials, #team, #contact, #footer,#features{width: 100% !important ;}
  .grid-container {    grid-template-columns: repeat(2, 1fr);}
  #Icon {    top: 59%;}
  .ContactleftPoint i {left: 16px;    top: 24px;    width: 42px !important;    height: 32px; font-size: 23px;}
  .ContactleftPoint span {font-size: 19px;}
  .ContactManinOuter h1 {font-size: 28px};
  .ContactFormouter{margin-top: 307px;}
  .ContactRightPart {    width: 111% !important;    margin-left: -34px; margin-top: 516px; }

  /* .ContactRightPart {    width: 144% !important;   margin-left: -67px;    margin-top: 516px;  } */
  .navbar-toggle {    position: absolute;    right: 93px; } 
  .navbar-brand img {    width: 50px;    height: 50px;    top: -4px;    left: 0;}
.letsouter{flex-direction: column;}
.letsleft, .letsright{width: 100%;}
/* .letsouter svg {  height: 35rem;} */
.letsright{margin-top: -90px; }
.letsright .form-group {  width: 93%;}

.careerOuter .add-file-btn{ margin-bottom: 20px;}
.message-label span:nth-of-type(2) { margin-left: 21px; }
#innerIcon{top: 0.1rem;}
.add-file-btn .fa{margin-right: 10px;}
.ContactOuter {  padding: 50px 10px;}
.popup-content {top: -17px;}

}