.navbar-brand img{
    width: 100px;
    height: 100px;
    position: absolute;
    top: -11px;
}

/* .navbar-toggle{
    display: block !important;
} */

.letsImg{
    width: 70px;
    height: 40px;
}

.page-scroll a{
    font-weight: 1000;
    color: #fff;
}

.navWord{
padding-top: 18px !important;
}


/* Header css  */
.overlay{
    position: absolute;
    top: 0;
}

.background-video{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

/* .navbar-default .navbar-collapse{
    background-color: #fff  !important;
} */


/* --------------------------- */
@media (width >= 768px) {
    .navbar-toggle {
        display: none;
        position: absolute;
        right: 0;
    }
}