body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.footer {
    position: relative;
    background: #000428;
    color: white;
    padding: 50px 20px;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.column {
    max-width: 300px;
}

.column h2 {
    margin-bottom: 20px;
    font-size: 23px;
    color: #6a93f9;
    text-align: center;
}

.footerOuter .section-title{
    margin-bottom: 20px;
}

.column ul {
    list-style: none;
    padding: 0;
}

.column ul li {
    margin: 5px 0;
}

.column ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}

.column p {
    margin: 25px 0;
}

.footerOuter form input {
    padding: 10px;
    width: calc(100% - 22px);
    margin-bottom: 10px;
}

.footerOuter form button {
    padding: 10px;
    width: 100%;
    background-color: #3E64FF;
    border: none;
    color: white;
    cursor: pointer;
}

.footerOuter form button:hover {
    background-color: #3457c1;
}

 .ifr{
    border-radius: 10px ;
}

/* body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.footer {
    position: relative;
    background: #000428;
    color: white;
    padding: 50px 20px;
}

.wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.column {
    max-width: 300px;
}

.column h2 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #3E64FF;
}

.column ul {
    list-style: none;
    padding: 0;
}

.column ul li {
    margin: 5px 0;
}

.column ul li a {
    color: #3E64FF;
    text-decoration: none;
}

.column p {
    margin: 10px 0;
}

form input {
    padding: 10px;
    width: calc(100% - 22px);
    margin-bottom: 10px;
}

form button {
    padding: 10px;
    width: 100%;
    background-color: #3E64FF;
    border: none;
    color: white;
    cursor: pointer;
}

form button:hover {
    background-color: #3457c1;
} */

/* 
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #282c34;
  }
  
  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .bubble {
    position: absolute;
    width: 50px;
    height: 50px;
    background: url('..//../Images/Logo.png') no-repeat center center/cover;
    border-radius: 50%;
    opacity: 0.7;
    animation: move 10s infinite linear;
  }
  
  @keyframes move {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(
        calc(100vw * var(--random-x)),
        calc(100vh * var(--random-y))
      );
    }
  }
  
  .bubble:nth-child(1) { --random-x: 0.1; --random-y: 0.2; }
  .bubble:nth-child(2) { --random-x: 0.3; --random-y: 0.4; }
  .bubble:nth-child(3) { --random-x: 0.5; --random-y: 0.1; }
   */








/* multiple icon */
   
   /* body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #282c34;
  } */
  
  /* .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .bubble {
    position: absolute;
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 50%;
    opacity: 0.7;
    animation: move 100s infinite linear;
  }
  
  .bubble:nth-child(1) { background: url('../../Images/logo12.jpeg') no-repeat center center; }
  .bubble:nth-child(2) { background: url('../../Images/logo12.jpeg') no-repeat center center; }
  .bubble:nth-child(3) { background: url('../../Images/logo12.jpeg') no-repeat center center; }
  
  @keyframes move {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(
        calc(100vw * var(--random-x)),
        calc(100vh * var(--random-y))
      );
    }
  }
  
  .bubble:nth-child(1) { --random-x: 0.1; --random-y: 0.2; }
  .bubble:nth-child(2) { --random-x: 0.3; --random-y: 0.4; }
  .bubble:nth-child(3) { --random-x: 0.5; --random-y: 0.1; } 
   */



   /* body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #282c34;
}

.footer-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #282c34;
}

.footer {
    position: relative;
    z-index: 1;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.column {
    flex: 1;
    margin: 0 20px;
}

.column h2 {
    margin-bottom: 20px;
}

.column ul {
    list-style: none;
    padding: 0;
}

.column ul li {
    margin-bottom: 10px;
}

.column ul li a {
    color: #fff;
    text-decoration: none;
}

.column p {
    margin-bottom: 10px;
}

.column form {
    display: flex;
    flex-direction: column;
}

.column input[type="email"] {
    padding: 10px;
    margin-bottom: 10px;
}

.column button {
    padding: 10px;
    background-color: #ff9800;
    color: #fff;
    border: none;
    cursor: pointer;
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

.bubble {
    position: absolute;
    width: 50px;
    height: 50px;
    background: url('..//../Images/Logo.png') no-repeat center center/cover;
    border-radius: 50%;
    opacity: 0.3;
    animation: move var(--speed) linear infinite;
}

@keyframes move {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(
            calc(100vw * var(--random-x)),
            calc(100vh * var(--random-y))
        );
    }
}

.bubble:nth-child(1) { --random-x: 0.1; --random-y: 0.2; }
.bubble:nth-child(2) { --random-x: 0.3; --random-y: 0.4; }
.bubble:nth-child(3) { --random-x: 0.5; --random-y: 0.1; } */
