.ContactManinOuter {
  padding-top: 100px;
}

.ContactOuter {
  padding: 50px;
  background-color: #162d42;
  color: #fff;
  width: 100%;
  height: auto;
}

.ContactleftPoint {
  width: 100%;
  float: left;
  border-radius: 5px;
  background: rgb(62 93 122 / 23%);
  padding: 27px 10px 10px 90px;
  position: relative;
  border: none;
  min-height: 80px;
  margin-bottom: 10px;
}

.ContactleftPoint i {
  position: absolute;
  left: 23px;
  top: 24px;
  width: 58px !important;
  height: 39px;
  border-right: 1px solid rgb(242 243 245 / 10%);
  padding: 2px 31px 11px 0;
  font-size: 30px;
}

.ContactleftPoint span {
  font-family: montserratregular;
  font-size: 26px;
  color: #fff;
  line-height: 34px;
}



.ContactFormouter {
  /* width: 100%;
    height: 600px;
    border-radius: 30px;
    border: 10px solid white; */
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px 20px;
  margin-top: -182px;
  border: 8px solid transparent;
  margin-left: 22px;
}

.ContactFormouter::before {
  content: "";
  position: absolute;
  inset: -16px;
  border-radius: 10px;
  background-color: #e7eaec;
  z-index: -9;
}

.ContactManinOuter h1,
.ContactManinOuter h3 {
  margin-left: 15px;
}

/* --------Speciaal input  */
.specinputSpan {
  width: 100%;
  border: 1px solid;
}

/* body {
    font-family: Arial, sans-serif;
    padding: 20px;
  } */

.input-container {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.ContactManinOuter .icon {
  position: absolute;
  left: 10px;
  top: 45%;
  transform: translateY(-50%);
  color: #486a81;
  font-size: 17px;
}

#Icon{
  top: 53%;

}

.input-field {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: none;
  border-bottom: 1px solid #486a81;
  outline: none;
  font-size: 16px;
  color: #486a81;
  transition: all 0.3s ease;
}

.input-field:focus {
  border-bottom: 3px solid #007bff;
}

.input-field:focus+.focus-border {
  width: 100%;
}

.input-field::placeholder {
  color: transparent;
  transition: all 0.3s ease;
}

.input-container label {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  color: #486a81;
  pointer-events: none;
  transition: all 0.3s ease;
  font-size: 17px;
  font-weight: 500;
}

.input-field:focus+.focus-border+label,
.input-field:not(:placeholder-shown)+.focus-border+label {
  top: -10px;

  /* Move further up on focus or when not empty */
  font-size: 12px;

  /* Smaller font size when moved up */
  color: #007bff;
}

.focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #007bff;
  transition: width 0.3s ease;
}


.message-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.message-label .icon {
  margin-right: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: vertical;
}

.file-upload {
  margin-bottom: 10px;
}

.add-file-btn {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

/* .add-file-btn::before {
    content: "\2795";
    margin-right: 5px;
} */

.enqBtn {
  background: 0 0;
  padding: 20px 26px 18px;
  color: #1866b1;
  border-color: #1866b1;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  min-width: 129px;
  border-radius: 5px;
  border: 1px solid #1866b1;
  margin: 10px auto;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all .5s ease;
}

.enqBtn:hover {
  background-color: #1866b1;
  color: #fff;
}

.belowFormPart {
  display: flex;
  justify-content: space-between;
}


/* Popup styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 50%); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure popup is above other content */
}

.popup-content {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  position: relative;
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  top: -410px;
    margin: 0 auto;
}

.popup-icon {
  margin-bottom: 20px;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.popup h3 {
  margin: 10px 0;
  font-size: 24px;
  color: #28a745; /* Green color for "Thank You" */
}

.popup p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Loader styles */
.loader {
  border: 8px solid rgb(0 0 0 / 10%); /* Light grey background */
  border-left: 8px solid #28a745; /* Green color for the loader */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

/* Loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* responsive   */

/* @media only screen and (min-width: 1200px)  {
  
}
@media only screen and (min-width: 992px)and (max-width: 1199px) {
  
}
@media only screen  and (min-width: 787px)and (max-width: 991px) {
  .ContactleftPoint i{font-size: 20px;}
  .ContactleftPoint span{font-size: 20px;}
  .ContactleftPoint {padding: 22px 10px 10px 90px;}
}
@media only screen  and (min-width: 640px)and (max-width: 786) {
  
}
@media only screen  and (min-width: 450px)and (max-width: 639px) {
  
}
@media only screen  and (min-width: 320px)and (max-width: 449px) {
  .ContactRightPart {
    width: 145%;
    margin-left: -67px;
    margin-top: 516px;
  }
} */