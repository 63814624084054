/* .careerOuter{
    background-color: #f3f7f8;
    padding: 100px 0 50px;
}
.careerGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}
.careerCard{
    height: auto;
    padding: 20px 10px;
    border-left: 5px solid #2e90e0;
    background-color: #fff;
}
.careerCard:hover{
    
} */

.careerOuter {
    background-color: #f3f7f8;
    padding: 100px 0 50px;
}

.careerGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.careerCard {
    height: auto;
    padding: 20px 10px;
    position: relative;
    background-color: #fff;
    border-left: 5px solid #2e90e0;
    overflow: hidden;
}

.careerCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #2e90e0;
    transition: width 0.5s;
    z-index: 1;
}

.careerCard:hover::before {
    width: 100%;
   }

.careerCard:hover h3{
    color: #fff;
    transition: 0.6s;
    transform: scale(1.5);
}

.careerCard h3 {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 10px;
    background-color: transparent;
}


.careerOuter .add-file-btn{ margin-bottom: 20px;}
.message-label span:nth-of-type(2) { margin-left: 21px; }
#innerIcon{top: 0.1rem;}
.add-file-btn .fa{margin-right: 10px;}

.CILeft{
    text-align: left;
}

.CILeft h3{
    font-size: 25px;
    font-weight: 700;
    margin:0 0  30px;
}

.CILeft div:nth-of-type(1){
    margin-bottom: 20px;
}

.CILeft li{
    font-size: 17px;
}

.CILeft li{
    list-style:inside;
}

.careerOuter .input-field{
    background-color: #f3f7f8;
}
.CIRIght{border: 2px solid #5f8cfd; padding: 30px; box-shadow: 0 0 10px #5f8cfd ;}

.carrerBg{ }